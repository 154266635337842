import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Hero from "./components/Hero";
import Main from "./components/Main";
import RankingList from "./components/RankingList";
import PlayerCard from "./components/PlayerCard";
import Footer from "./components/Footer";
import data from "./data";
import BurgerNav from "./components/BurgerNav";
import { useState } from "react";

function App() {
  const admins = data.map((item) => {
    return <PlayerCard key={item.id} {...item} />;
  });

  const [burgerNavOpen, setBurgerNavOpen] = useState(false);

  function showBurgerNav() {
    setBurgerNavOpen((prevBurgerNavOpen) => {
      return !prevBurgerNavOpen;
    });
  }

  return (
    <div className="App">
      <Router>
        <nav>
          <img
            src={require("./images/chrisley_logo.png")}
            alt="Logo"
            className="nav--logo"
          />
          <h3 className="nav--title">ChrisleyBeer</h3>
          <ul className="nav--menu">
            <li>
              <Link to="/" className="text--link">
                Home
              </Link>
            </li>
            <li>
              <Link to="/ranking" className="text--link">
                Ranking List
              </Link>
            </li>
            <li>
              <Link to="/admins" className="text--link">
                Admins
              </Link>
            </li>
          </ul>
          <button className="nav--hamburger" onClick={showBurgerNav}>
            <img
              src={require("./images/hamburger.png")}
              alt="Hamburger"
              className="hamburger--icon"
            />
          </button>
        </nav>
        <BurgerNav burgerNavOpen={burgerNavOpen} />
        <Hero />

        <Routes>
          <Route path="/ranking" element={<RankingList />} />
          <Route path="/" element={<Main />} />
          <Route
            path="/admins"
            element={
              <div>
                <h1 className="admin--text">Admins</h1>
                <section className="admin--list">{admins}</section>
              </div>
            }
          />
        </Routes>

        <Footer />
      </Router>
    </div>
  );
}

//Testing for routing

export default App;
