export default function PlayerCard(props) {
  return (
    <div className="card">
      <img
        src={require(`../images/${props.playerImg}`)}
        className="card--image"
        alt="Player Character"
      />
      <h2 className="card--name">{props.username}</h2>
      <h3 className="card--power">CP {props.combatPower}</h3>
      <div className="card--contact">
        <a href={props.mailto}>
          <img
            src={require("../images/mail-icon.png")}
            alt="mail"
            className="card--mail--icon"
          />
        </a>
        <a href={props.mailto}>
          <h3 className="card--mail--adress">{props.emailAdress}</h3>
        </a>
      </div>
    </div>
  );
}
