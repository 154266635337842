import { Link } from "react-router-dom";

export default function BurgerNav(props) {
  return (
    <ul className={`burger--menu ${!props.burgerNavOpen ? "hide" : ""}`}>
      <li className="burger--link">
        <Link to="/" className="text--link">
          Home
        </Link>
      </li>
      <li className="burger--link">
        <Link to="/ranking" className="text--link">
          Ranking List
        </Link>
      </li>
      <li className="burger--link">
        <Link to="/admins" className="text--link">
          Admins
        </Link>
      </li>
    </ul>
  );
}
