export default function Hero() {
  return (
    <div>
      <img
        src={require("../images/hero_image.png")}
        alt="Hero"
        className="hero--image"
      />
    </div>
  );
}
