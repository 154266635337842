export default function Main() {
  return (
    <main>
      <h1 className="main--title">Welcome to the league!</h1>
      <h2 className="main--message">
        Mind helping expand our league by inviting new people?
      </h2>
      <h2 className="main--message">
        If you want changes to happen within the league, please contact us!
      </h2>
    </main>
  );
}
