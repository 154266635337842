export default function Footer() {
  return (
    <footer>
      <a href="mailto:support@chrisleybeer.nl">
        <img
          src={require("../images/mail-icon.png")}
          alt="mail"
          className="footer--mail--icon"
        />
      </a>
      <a href="mailto:support@chrisleybeer.nl" className="footer--mailadress">
        <h4>support@chrisleybeer.nl</h4>
      </a>
      <h4 className="footer--credits">@2022 RandomRickGames</h4>
    </footer>
  );
}
