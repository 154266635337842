export default [
  {
    id: 1,
    username: "ChrisleyBeer",
    combatPower: "351",
    emailAdress: "chrisleybeer@chrisleybeer.nl",
    mailto: "mailto:chrisleybeer@chrisleybeer.nl",
    playerImg: "player-image-1.jpg",
  },
  {
    id: 2,
    username: "RandomRickGames",
    combatPower: "318",
    emailAdress: "randomrickgames@chrisleybeer.nl",
    mailto: "mailto:randomrickgames@chrisleybeer.nl",
    playerImg: "player-image-2.jpg",
  },
];
