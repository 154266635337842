export default function RankingList() {
  return (
    <div className="ranking">
      <h1 className="ranking--title">Ranking List</h1>
      <h4 className="ranking--disclaimer">
        The rank General is reserved for admins only
      </h4>
      <h4 className="ranking--description">
        Ranks are based on in-game level and Combat Power
      </h4>
      <div className="ranking--list">
        <ul className="ranking--items">
          <li>CP 200</li>
          <li>CP 100</li>
          <li>CP 60</li>
          <li>Lvl 30</li>
          <li>Lvl 20</li>
          <li>Lvl 10</li>
          <li>Lvl 4</li>
          <li>Lvl 0</li>
        </ul>
        <ul className="ranking--items">
          <li>=</li>
          <li>=</li>
          <li>=</li>
          <li>=</li>
          <li>=</li>
          <li>=</li>
          <li>=</li>
          <li>=</li>
        </ul>
        <ul className="ranking--items">
          <li>Lieutenant General</li>
          <li>Colonel</li>
          <li>Captain</li>
          <li>Sergeant Major</li>
          <li>Sergeant First Class</li>
          <li>Corporal</li>
          <li>Private</li>
          <li>Recruit</li>
        </ul>
      </div>
    </div>
  );
}
